<template>
  <div class="page-layout about">
    
    <HeaderIntroImage marginbottom :headerMetadata="pageData"></HeaderIntroImage>
      
    <div class="container backstep">
    <div class="step">
        <p class="detail--tag blue icon-left">Primary And Secondary School</p>

        <h2>Quantum Mechanics Concept Assessment (QMCA)</h2>

        <div class="tag-grid">
        <div class="cell">
            <p class="label">type</p>
            <p class="label blue">multiple-choice</p>
        </div>
        <div class="cell">
            <p class="label">language</p>
            <p class="bold">english, spanish</p>
        </div>
        <div class="cell">
            <p class="label">duration</p>
            <p class="bold">50 min</p>
        </div>
        </div>

        <div class="content">
        <p class="label">
            EVIDENCE FOR EMPIRICAL QUALITY
        </p>
        <p class="subtitle blue">
            MODERN / Quantum Content knowledge (wave functions, measurement, time dependence, probability, infinite square well, 1D tunneling, energy levels, spins)
        </p>

        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren</p>
        </div>

        <div class="attachments-grid">
        <div class="row1">
            <div class="box">
            <p class="label">targets</p>
            <p class="detail--tag blue icon-left">Undergraduated students</p>
            <p class="detail--tag blue icon-left">Teachers</p>
            </div>

            <div class="box">
            <a href="" class="btn border external">Online test</a>
            </div>
        </div>

        <div class="row2">
            <div class="box attachments">
              <p class="label">material</p>
              <p class="download"><a class="bold icon-right">QMCA - multiple choice</a></p>
              <p class="download"><a class="bold icon-right">QMCA - multiple choice</a></p>
            </div>

            <div class="box">
            <p class="label">contact person</p>
            <MemberCardMin addclass="member"></MemberCardMin>
            </div>
        </div>
        </div>
        
    </div>
    </div>

  </div>
</template>

<script>
import HeaderIntroImage from '../../components/layout-blocks/HeaderIntroImage.vue'
export default {
  name: 'about',
  components: {
    HeaderIntroImage,   
  },
  props: {},
  data: () => {
    return {
      pageData: null
    }
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    }
  },
  methods: {},
  mounted() {
    this.pageData = {
      attributes: {
        title: "",
        subtitle: "",
        tag: ""
      }
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";


</style>